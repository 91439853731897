<template>
	<div class="orderRefund">
		<el-card class="box-card father-card" shadow="never">
			<el-row class="btns">
				<el-form ref="form" :model="form" inline>
					<el-form-item label="订单编号">
						<el-input v-model="form.orderNo" placeholder="请输入订单编号" clearable></el-input>
					</el-form-item>
					<el-form-item label="姓名">
						<el-input v-model="form.memeberName" placeholder="请输入用户姓名" clearable></el-input>
					</el-form-item>
					<el-form-item label="手机号码">
						<el-input v-model="form.memeberPhone" placeholder="请输入手机号码" clearable></el-input>
					</el-form-item>
					<el-form-item label="支付类型">
						<el-select v-model="form.payType" placeholder="支付类型" clearable>
							<el-option label="预付" value="0"></el-option>
							<el-option label="完成支付" value="1"></el-option>
                            <el-option label="补充收款" value="2"></el-option>
						</el-select>
					</el-form-item>
                    <el-form-item label="订单开始结束时间">
                        <WTimer ref="WTimer" v-model="form" :startEnd="{start:'starOrderDate',end:'endOrderDate'}" />
                    </el-form-item>
					<!-- <el-form-item label="订单开始时间" prop="disinfectTime">
						<el-date-picker v-model="form.starOrderDate" type="datetime" placeholder="开始时间"
							label-width="10px" clearable format="yyyy-MM-dd" value-format="yyyy-MM-dd">
						</el-date-picker>
					</el-form-item>
					<el-form-item label="到" prop="disinfectTime">
						<el-date-picker v-model="form.endOrderDate" type="datetime" placeholder="结束时间" clearable
							format="yyyy-MM-dd" value-format="yyyy-MM-dd">
						</el-date-picker>
					</el-form-item> -->
					<!-- 按钮区域 -->
					<el-form-item>
						<el-button type="primary" icon="el-icon-search" @click="search()">搜索</el-button>
						<el-button type="primary" icon="el-icon-delete" @click="reset">重置</el-button>
						<el-button type="info" icon="el-icon-share" @click="toExcel()">导出</el-button>
					</el-form-item>
				</el-form>
			</el-row>
			<el-row class="tab">
				<!-- 表格区域 -->
				<el-table ref="multipleTable" :data="List" tooltip-effect="dark" style="width: 100%"
					highlight-current-row stripe border :header-cell-style="rowClass">
					<el-table-column prop="orderNo" label="订单编号" align="center"></el-table-column>
					<el-table-column prop="orderChannel" label="支付渠道" align="center">
						<template slot-scope="scope">
							<span effect="dark" type="success" v-if="scope.row.payChannel === '1'">{{ "微信" }}</span>
							<span effect="dark" v-else-if="scope.row.payChannel === '2'">{{ "支付宝" }}</span>
							<span effect="dark" type="warning" v-else-if="scope.row.payChannel === '3'">{{ "账户余额" }}
							</span>
							<span type="danger" effect="dark" v-else-if="scope.row.payChannel === '4'">{{"二维码"}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="orderType" label="支付类型" align="center">
						<template slot-scope="scope">
							<span effect="dark" type="warning" v-if="scope.row.payType === '0'">{{ "预付" }}</span>
							<span type="success" effect="dark" v-else-if="scope.row.payType === '1'">{{"完成支付"}}</span>
                            <span type="success" effect="dark" v-else-if="scope.row.payType === '2'" >{{"补充收款"}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="payFee" label="支付费用" align="center"></el-table-column>
					<!-- <el-table-column prop="memeberId" label="申请退款人ID" align="center"></el-table-column> -->
					<el-table-column prop="memeberName" label="申请退款人名" align="center"></el-table-column>
					<el-table-column prop="memeberPhone" label="申请退款人手机号" align="center"></el-table-column>
                    <el-table-column prop="refundFee" label="退款金额" align="center"></el-table-column>
                    <el-table-column prop="remark" label="备注" align="center"></el-table-column>
					<el-table-column prop="applyTime" label="申请时间" align="center"></el-table-column>
					<el-table-column label="详情" align="center">
						<template slot-scope="scope">
							<el-button size="mini" @click="look(scope.row)" type="success">查看详情</el-button>
						</template>
					</el-table-column>
				</el-table>
				<!-- 分页区域 -->
				<el-pagination @size-change="handleSizeChange" @current-change="handlePageNumChange"
					:current-page="paging.pageNo" :page-sizes="[1, 10, 20, 50]" :page-size="paging.pageSize"
					layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
				<!-- 订单详情弹出框 -->
				<el-dialog :visible.sync="orderDialogFormVisible" class="order-dialog">
					<span slot="title" class="dialog-Title">订单退款详情</span>
					<el-form :model="orderForm" inline ref="orderForm">
						<!-- 第一行 -->
						<el-row>
							<el-form-item label="订单编号" prop="orderNo">
								<el-input v-model="orderForm.orderNo" autocomplete="off" :disabled="isdisable">
								</el-input>
							</el-form-item>
							<el-form-item label="支付渠道" prop="orderChannel">
								<el-input v-model="orderForm.orderChannel" autocomplete="off" :disabled="isdisable">
								</el-input>
							</el-form-item>
							<el-form-item label="支付类型" prop="orderType">
								<el-input v-model="orderForm.orderType" autocomplete="off" :disabled="isdisable">
								</el-input>
							</el-form-item>
						</el-row>
						<!-- 第二行 -->
						<el-row>
							<el-form-item label="支付费用" prop="payFee">
								<el-input v-model="orderForm.payFee" autocomplete="off" :disabled="isdisable">
								</el-input>
							</el-form-item>
							<el-form-item label="申请退款人ID" prop="memeberId">
								<el-input v-model="orderForm.memeberId" autocomplete="off" :disabled="isdisable">
								</el-input>
							</el-form-item>
							<el-form-item label="申请退款人名" prop="memeberName">
								<el-input v-model="orderForm.memeberName" autocomplete="off" :disabled="isdisable">
								</el-input>
							</el-form-item>
						</el-row>
						<!-- 第三行 -->
						<el-row>
							<el-form-item label="申请退款人手机号" prop="memeberPhone">
								<el-input v-model="orderForm.memeberPhone" autocomplete="off" :disabled="isdisable">
								</el-input>
							</el-form-item>
							<el-form-item label="申请时间" prop="applyTime">
								<el-input v-model="orderForm.applyTime" autocomplete="off" :disabled="isdisable">
								</el-input>
							</el-form-item>
							<el-form-item label="审核人" prop="auditId">
								<el-input v-model="orderForm.auditId" autocomplete="off" :disabled="isdisable">
								</el-input>
							</el-form-item>
						</el-row>
						<!-- 第四行 -->
						<el-row>
							<el-form-item label="审核时间" prop="auditTime">
								<el-input v-model="orderForm.auditTime" autocomplete="off" :disabled="isdisable">
								</el-input>
							</el-form-item>
							<el-form-item label="审核意见" prop="auditReason">
								<el-input v-model="orderForm.auditReason" autocomplete="off" :disabled="isdisable">
								</el-input>
							</el-form-item>
							<el-form-item label="出纳员划账时填" prop="refundCasher">
								<el-input v-model="orderForm.refundCasher" autocomplete="off" :disabled="isdisable">
								</el-input>
							</el-form-item>
						</el-row>
						<!-- 第五行 -->
						<el-row>
							<el-form-item label="退款费用" prop="refundFee">
								<el-input v-model="orderForm.refundFee" autocomplete="off" :disabled="isdisable">
								</el-input>
							</el-form-item>
							<el-form-item label="退款时间" prop="refundTime">
								<el-input v-model="orderForm.refundTime" autocomplete="off" :disabled="isdisable">
								</el-input>
							</el-form-item>
							<el-form-item label="状态" prop="status">
								<el-input v-model="orderForm.status" autocomplete="off" :disabled="isdisable">
								</el-input>
							</el-form-item>
						</el-row>
					</el-form>
					<div slot="footer" class="dialog-footer">
						<el-button type="primary" @click="orderDialogFormVisible=false">确 认</el-button>
					</div>
				</el-dialog>
			</el-row>
		</el-card>
	</div>
</template>

<script>
import WTimer from '@/components/dateCpn/dataPiceker'

export default {
  name: 'orderRefund',
  components: {
    WTimer
  },
  data () {
    return {
      List: [], // 表格数据源
      form: {
        orderNo: '',
        memeberName: '',
        memeberPhone: '',
        payType: '',
        starOrderDate: '',
        endOrderDate: '',
        status: ''
      }, // 搜索表单
      paging: {
        pageSize: 10, // 每页显示数据条数
        PageNo: 1 // 当前页数
      }, // 分页表单
      total: 0, // 总的数据条数
      orderDialogFormVisible: false,
      orderForm: {},
      orderFormRules: {
        dispatchItem: [{
          required: true,
          message: '请输入项目名称',
          trigger: 'blur'
        }]
      }, // 编辑资料表单验证规则
      isdisable: true,
      fileName: '',
      selectedWorkName: {
        companyName: ''
      },
      comName: ''
    }
  },
  created () {
    this.getOrderList()
  },
  methods: {
    async scompany (id) {
      this.selectedWorkName = this.orderCompanyList.find((item) => { // 这里的chargingWorkNameList就是上面遍历的数据源
        return item.uuid === id
        // 筛选出匹配数据，是对应数据的整个对象
      })
    },
    // 给表格头部设定背景色
    rowClass ({
      row,
      rowIndex
    }) {
      return 'background:#e4eaec'
    },
    // 切换每页显示数量时触发
    handleSizeChange (newSize) {
      this.paging.pageSize = newSize
      this.getOrderList()
    },
    // 切换页码时触发
    handlePageNumChange (PageNum) {
      this.paging.PageNo = PageNum
      this.getOrderList()
    },
    // 获取订单退款
    async getOrderList () {
      const {
        data: res
      } = await this.$http.post('/pmService/tInfoOrderRefund/getTInfoOrderRefundList', {
        pageSize: this.paging.pageSize,
        pageNo: this.paging.PageNo,
        ...this.form
      })
      // if (res.statusCode !== "200") return this.$message.error('获取订单列表失败')
      this.List = res.data.records
      this.total = +res.data.total
      console.log(res)
    },
    // 点击搜索按钮触发
    async search () {
      this.paging.PageNo = 1
      const {
        data: res
      } = await this.$http.post('/pmService/tInfoOrderRefund/getTInfoOrderRefundList', {
        pageSize: this.paging.pageSize,
        pageNo: this.paging.PageNo,
        orderNo: this.form.orderNo,
        memeberName: this.form.memeberName,
        memeberPhone: this.form.memeberPhone,
        payType: this.form.payType,
        starOrderDate: this.form.starOrderDate,
        endOrderDate: this.form.endOrderDate
      })
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('查询失败')
      this.List = res.data.records
      this.total = +res.data.total
    },
    // 点击查看详情按钮触发
    look (v) {
      this.orderForm = v
      this.orderDialogFormVisible = true
    },
    // 导出订单
    toExcel () {
      if (!this.form.starOrderDate || !this.form.endOrderDate) {
        return this.$message.error('请选择订单开始时间')
      }
      var date = new Date(this.form.startOrderDate)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? ('0' + m) : m
      var d = date.getDate()
      d = d < 10 ? ('0' + d) : d
      const time = y + '-' + m + '-' + d
      var daten = new Date(this.form.endOrderDate)
      var yn = daten.getFullYear()
      var mn = daten.getMonth() + 1
      mn = mn < 10 ? ('0' + mn) : mn
      var dn = daten.getDate()
      dn = dn < 10 ? ('0' + dn) : dn
      const endtime = yn + '-' + mn + '-' + dn
      if (this.selectedWorkName.companyName && this.form.startOrderDate && this.form.endOrderDate) {
        this.fileName = this.selectedWorkName.companyName + '退款订单汇表'
        this.exportExcel(this.fileName + time + '到' + endtime)
      } else if (this.selectedWorkName.companyName && this.form.startOrderDate == '' && this.form.endOrderDate ==
					'') {
        this.fileName = this.selectedWorkName.companyName + '退款订单汇表'
        this.exportExcel(this.fileName)
      } else if (this.selectedWorkName.companyName && this.form.startOrderDate && this.form.endOrderDate == '') {
        this.fileName = this.selectedWorkName.companyName + '退款订单汇表'
        this.exportExcel(this.fileName + time)
      } else if (this.selectedWorkName.companyName && this.form.endOrderDate && this.form.startOrderDate == '') {
        this.fileName = this.selectedWorkName.companyName + '退款订单汇表'
        this.exportExcel(this.fileName + endtime)
      } else {
        this.fileName = '退款订单总汇表'
        this.exportExcel(this.fileName)
      }
    },
    async exportExcel (value) {
      if (this.selectedWorkName.companyName) {
        this.comName = this.selectedWorkName.companyName
      } else {
        this.comName = '' // 全机构
      }
      const data = {
        status: this.form.status,
        companyId: this.form.region,
        pageSize: this.paging.pageSize,
        companyName: this.comName,
        pageNo: this.paging.PageNo,
        orderNo: this.form.orderNo,
        starOrderDate: this.form.starOrderDate,
        endOrderDate: this.form.endOrderDate,
        memeberName: this.form.memeberName,
        memeberPhone: this.form.memeberPhone,
        payType: this.form.payType
      }
      console.log(data, 'data')
      // http://192.168.123.190:9000
      await this.$http.post('/pmService/tInfoOrderRefund/OrderRefundToExcel',
        data, { // /userManageServer/pay/ToExcel
          responseType: 'blob'
        }).then(res => {
        console.log(res.data)
        const blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel'
        })
        // 第一种
        // '订单汇表.xlsx'
        const fileName = value
        const elink = document.createElement('a')
        elink.download = fileName
        elink.style.display = 'none'
        elink.href = URL.createObjectURL(blob)
        document.body.appendChild(elink)
        elink.click()
        URL.revokeObjectURL(elink.href) // 释放URL 对象
        document.body.removeChild(elink)
      })
    },
    reset () { // 重置
      this.form.orderNo = ''
      this.form.memeberName = ''
      this.form.memeberPhone = ''
      this.form.payType = ''
      this.form.starOrderDate = ''
      this.form.endOrderDate = ''
      this.form.status = ''
      this.$refs.WTimer.resetFields()
      this.paging.pageSize = 10
      this.paging.PageNo = 1
      this.search()
    }
  }

}
</script>
<style lang="less" scoped>
	.orderRefund {
		width: 100%;
		height: 100%;

		.father-card {
			.btns {
				margin-bottom: 10px;
			}

			.tab {
				.el-pagination {
					margin-bottom: 0.25rem;
					margin-top: 0.25rem;
					margin-right: 0.25rem;
					text-align: right;
				}

				.order-dialog {
					.dialog-Title {
						color: #0097fe;
						font-size: 20px;
						font-weight: 700;
					}

					/deep/ .el-form {
						.subtitle {
							color: #0097fe;
							font-size: 14px;
							font-weight: 700;
							margin-bottom: 20px;
							padding-left: 6px;
							border-left: 5px solid #0097fe;
						}

						.el-row {
							display: flex;

							.el-form-item {
								flex: 1;
								display: flex;

								.el-form-item__content {
									flex: 1;

									.el-input {
										width: 100%;

										.el-input__inner {
											width: 100%;
										}
									}

									.el-select {
										width: 100%;
									}
								}
							}
						}
					}
				}
			}
		}
	}
</style>
